
import {Options, Vue} from 'vue-class-component';

const url = location.hostname == 'localhost' ? 'http://localhost:8081' : '/api';

type Comment = {
  who: {
    name: string,
    party?: string
  },
  says: string,
  time?: string,
  source?: string
}

async function getRandomComment(): Promise<Comment> {
  let response  = await fetch(url+'/random', {
    method: 'GET'
  });
  return await response.json();
}



@Options({
  data() {
    return {
      randomComment: null
    };
  },
  async created() {
    this.randomComment = await getRandomComment();
  },
  methods: {
    async fetchRandomComments() {
      this.randomComment = await getRandomComment();
    }
  }
})
export default class HelloWorld extends Vue {}
