<template>
  <div class="hello">
    <h1>{{ randomComment?.says }}</h1>
    <p>{{ randomComment?.who.name }} ({{ randomComment?.who.party }})</p>
    <a class="source" v-bind:href="randomComment?.source" target="_blank">{{ randomComment?.time?.date }}</a>
  </div>
  <button type="button" @click="fetchRandomComments">neues Zitat</button>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';

const url = location.hostname == 'localhost' ? 'http://localhost:8081' : '/api';

type Comment = {
  who: {
    name: string,
    party?: string
  },
  says: string,
  time?: string,
  source?: string
}

async function getRandomComment(): Promise<Comment> {
  let response  = await fetch(url+'/random', {
    method: 'GET'
  });
  return await response.json();
}



@Options({
  data() {
    return {
      randomComment: null
    };
  },
  async created() {
    this.randomComment = await getRandomComment();
  },
  methods: {
    async fetchRandomComments() {
      this.randomComment = await getRandomComment();
    }
  }
})
export default class HelloWorld extends Vue {}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .source {
    color: darkgrey;
    text-decoration: none;
    display: inline-block;
    margin-bottom: 1rem;
  }
</style>
